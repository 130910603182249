body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

openapi-explorer::part(section-navbar) {
  box-shadow: 1px 0 5px -2px #9E9E9E;
}

openapi-explorer::part(section-main-content) {
  background: #F6F9FF;
}

openapi-explorer {
  --nav-bg-color: #FFFFFF;
  --text-color: #6B6B6B;
  --nav-text-color:  #6B6B6B;
  --nav-hover-text-color:  black;
  --nav-hover-bg-color: #EAF2FF;
  --primary-color: #05149E;
  --secondary-color: #05149E;
  --header-bg-color: black;
  --bg-color: #F6F9FF;
}

  
